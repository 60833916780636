<template>
  <div class="informer">
    <div class="informer__navbar">
      <div class="informer__navbar-top">
        <h1>{{ $t('calculation-of-damage-directories') }}</h1>
        <div class="informer__navbar-top-items">
          <router-link
              :to="item.path"
              class="incident-types"
              v-for="(item, key) in links"
              :key="key"
          >{{ item.name }}</router-link>
        </div>
      </div>
      <ui-menu v-model="visibleSettings">
        <template #activator>
          <img alt="" :src="require(`@/assets/svg/icon-settings-${settingsColor}.svg`)" class="nav-bar__settings" />
        </template>
        <template #body>
          <div class="sort">
            <router-link
                v-for="(link, index) in links"
                :to="link.path"
                :key="index"
                class="nav-bar__links-link"
                @click.native="visibleSettings = !visibleSettings"
            >
              {{ link.name }}
            </router-link>
          </div>
        </template>
      </ui-menu>
      <p class="informer__navbar-bottom" @click="visibleAlert = true" v-if="getDeletedSpecsTypes.length || getDeletedFormula.length">{{ $t('delete') }} {{ getTableName }}</p>
      <ui-alert v-model="visibleAlert">
        <div class="ui-alert__wrap">
          <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete') }} {{ getTableName }}?</div>
        </div>
        <div class="ui-alert__action">
          <ui-button @click="deleteTable" color="error">{{ $t('delete') }} {{ getTableName }}</ui-button>
          <ui-button @click="visibleAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
        </div>
      </ui-alert>
    </div>
    <router-view />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiMenu from "@/components/ui/UiMenu.vue";

export default {
  name: "Index",
  components: {UiMenu, UiButton, UiAlert},

  data() {
    return {
      visibleAlert: false,
      visibleSettings: false,
      links: [
        {id: 1, name: this.$t('incident-types'), path: '/admin/informer/incident-types'},
        {id: 2, name: this.$t('subtypes'), path: '/admin/informer/subtypes'},
        {id: 3, name: this.$t('custom-variables'), path: '/admin/informer/user-variables'},
        {id: 4, name: this.$t('taxes-and-ratios'), path: '/admin/informer/taxes-coefficients'},
        {id: 5, name: this.$t('formulas'), path: '/admin/informer/formulas'}
      ]
    }
  },
  created() {
    this.types()
  },
  methods: {
    ...mapActions(['types', 'deleteVariable', 'specsTypes', 'columns', 'deleteFormula', 'formulas', 'formulasActions']),
    ...mapMutations(['setUserVariablesLoader']),
    deleteTable() {
      if(this.getDeletedSpecsTypes.length) {
        this.setUserVariablesLoader(true)
        this.deleteVariable(this.getDeletedSpecsTypes[0].id)
            .then(() => {
              let payload = {
                orderTypeId: this.getDeletedSpecsTypes[0].orderTypeId,
                orderSubtypeId: this.getDeletedSpecsTypes[0].orderSubtypeId,
                specGroup: this.getDeletedSpecsTypes[0].specGroup
              };
              this.specsTypes(payload).then(() => {
                setTimeout(() => {
                  this.setUserVariablesLoader(false)
                }, 500)
                this.getSpecsTypesKey.forEach(el =>
                    this.columns({specTypeId: el.id}).then((res) => this.getSpecsTypesKey.forEach(val => {
                          if (val.id === res[0].specTypeId) {
                            val.columns = res
                          }
                        })
                    ))
              })
              this.visibleAlert = false
              this.$store.commit('cleanDeletedSpecsTypes')
            })
      }else if(this.getDeletedFormula.length) {
        this.setUserVariablesLoader(true)
        this.deleteFormula({id: this.getDeletedFormula[0].id}).then(() => {
          let payload = {
            orderTypeId: this.getDeletedFormula[0].orderTypeId,
            orderSubtypeId: this.getDeletedFormula[0].orderSubtypeId,
          };

          this.formulas(payload).then(() => {
            this.formulasActions(payload).then(() => {
            })
            this.setUserVariablesLoader(false)
            this.visibleAlert = false
            this.$store.commit('cleanDeletedFormula')
          })
        })
      }
    }
  },

  computed: {
    ...mapGetters(['getDeletedSpecsTypes', 'getSpecsTypesKey', 'getDeletedFormula']),
    getTableName () {
      return this.getDeletedFormula.length ? this.$t('formula') : this.$t('table')
    },
    settingsColor () {
      return this.visibleSettings ? 'red' : 'black'
    }
  }

}
</script>

<style lang="scss" scoped>
  .informer {
    display: flex;
    gap: 50px;
    min-height: 100vh;
    height: 100%;
    padding: 150px 50px 50px 50px;

    @media(max-width: 1200px) {
      flex-direction: column;
      padding: 120px 20px 50px 20px;
    }

    &__navbar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      @media (max-width: 1200px) {
        flex-direction: row;
      }

      &-top {
        display: flex;
        flex-direction: column;
        gap: 50px;

        h1 {
          font-weight: 400;
          font-size: 20px;
          line-height: 22px;
          color: #1B1A1F;
          white-space: nowrap;
        }

        &-items {
          display: flex;
          flex-direction: column;

          @media (max-width: 1200px) {
            display: none;
          }

          .incident-types {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #1B1A1F;
            margin-bottom: 20px;
            white-space: nowrap;
          }

          .router-link-active {
            color: #CE2121
          }
        }
      }

      &-bottom {
        color: #9A9A9A;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;

        @media (max-width: 1200px) {
          position: absolute;
          top: 30px;
        }
      }
    }
  }
  .nav-bar__settings {
    display: none;

    @media (max-width: 1200px) {
      display: inline-block;
    }
  }
</style>